import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container, Col, Row } from 'react-grid-system';
import Heading from 'components/Heading';
import ContentIcon from 'components/ContentIcon';
import Card from 'components/Card';
import breakpoints from 'utils/breakpoints';

const Wrapper = styled.section`
  padding: 0 0 100px;

  @media screen and (min-width: ${breakpoints.md}) {
    padding: 0 0 150px;
  }
`;

const Cards = styled(Row)`
  margin-top: 30px;
`;

const CardWrap = styled(Col)`
  margin-bottom: 10px;

  @media screen and (min-width: ${breakpoints.md}) {
    margin-bottom: 20px;
  }

  @media screen and (min-width: ${breakpoints.lg}) {
    margin-bottom: 0;
  }
`;

function DataPrivacy({ className, title, cards }) {
  return (
    <Wrapper className={className}>
      <Container>
        <Row justify="center">
          <Col lg={5}>
            <Heading centered title={title} />
          </Col>
        </Row>
        <Cards>
          {cards.map(card => (
            <CardWrap lg={3} md={6}>
              <Card
                centered
                key={card.title}
                title={card.title}
                icon={<ContentIcon type={card.iconType} />}
              >
                {card.description}
              </Card>
            </CardWrap>
          ))}
        </Cards>
      </Container>
    </Wrapper>
  );
}

DataPrivacy.defaultProps = {
  className: null,
};

DataPrivacy.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      descritpion: PropTypes.string,
      iconType: ContentIcon.propTypes.type,
    })
  ).isRequired,
};

export default DataPrivacy;
