import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import breakpoints from 'utils/breakpoints';

import { createPortal } from 'react-dom';
import useFirstRender from './useFirstRender';

const Wrapper = styled.div`
  display: ${props => (props.hidden ? 'none' : 'block')};
`;

const Mask = styled.div`
  position: fixed;
  inset: 0;
  z-index: 10000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
`;

const Content = styled.div`
  position: fixed;
  inset: 0;
  z-index: 10000;
  overflow: auto;
  max-height: 350px;
  padding: 1rem;
  border-radius: 12px;
  width: 85% !important;
  background: #fff;
  left: 50%;
  top: 150px;
  transform: translate(-50%, 0);
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: ${breakpoints.md}) {
    width: 70% !important;
  }
`;

function RawModal({ children, onClose, className, hidden }) {
  if (typeof document !== 'undefined') {
    return createPortal(
      <Wrapper className={className} hidden={hidden}>
        <Mask onClick={onClose} className="modal-mask" />
        <Content className="modal-content">{children}</Content>
      </Wrapper>,
      document.body
    );
  }
  return (
    <Wrapper className={className} hidden={hidden}>
      <Mask onClick={onClose} className="modal-mask" />
      <Content className="modal-content">{children}</Content>
    </Wrapper>
  );
}

RawModal.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  className: PropTypes.string,
  hidden: PropTypes.bool,
};

RawModal.defaultProps = {
  children: null,
  onClose: null,
  className: '',
  hidden: false,
};

function Modal({ children, open, onClose, className }) {
  const isFirstRender = useFirstRender();

  useEffect(() => {
    if (typeof document !== 'undefined') {
      if (open) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    }
  }, [open]);

  if (open) {
    return (
      <RawModal className={className} onClose={onClose} hidden={false}>
        {children}
      </RawModal>
    );
  }

  if (isFirstRender) {
    return null;
  }

  return (
    <RawModal className={className} onClose={onClose} hidden>
      {children}
    </RawModal>
  );
}

Modal.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string,
};

Modal.defaultProps = {
  children: null,
  open: false,
  onClose: null,
  className: '',
};

export default Modal;
