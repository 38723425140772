import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container, Col, Row } from 'react-grid-system';
import ContentIcon from 'components/ContentIcon';
import Card from 'components/Card/NoBorder';
import Markdown from 'react-markdown';
import breakpoints from 'utils/breakpoints';

const Wrapper = styled.section`
  @media screen and (min-width: ${breakpoints.md}) {
    background: #f4f9ff;
    padding: 70px 0 0;
  }
`;

const StyledCard = styled(Card)`
  padding-bottom: 0;
`;

function Cards({ className, cards }) {
  return (
    <Wrapper className={className}>
      <Container>
        <Row justify="center">
          {cards.map(card => (
            <Col xs={10} md={6} lg={3}>
              <StyledCard
                centered
                icon={<ContentIcon type={card.iconType} />}
                key={card.description}
              >
                <Markdown source={card.description} />
              </StyledCard>
            </Col>
          ))}
        </Row>
      </Container>
    </Wrapper>
  );
}

Cards.defaultProps = {
  className: null,
};

Cards.propTypes = {
  className: PropTypes.string,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      iconType: ContentIcon.propTypes.type,
      description: PropTypes.string,
    })
  ).isRequired,
};

export default Cards;
