import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import starsBg from 'assets/home/stars.png';
import twinklingBg from 'assets/home/twinkling.png';

const AnimatedBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
  background-attachment: fixed;
`;

const moveTwinkBack = keyframes`
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
`;

const Stars = styled(AnimatedBackground)`
  background: #000 url(${starsBg}) repeat top center;
  z-index: 0;
`;

const Twinkling = styled(AnimatedBackground)`
  background: transparent url(${twinklingBg}) repeat top center;
  z-index: 1;
  animation: ${moveTwinkBack} 200s linear infinite;
`;

function Background({ className }) {
  return (
    <Stars className={className}>
      <Twinkling />
    </Stars>
  );
}

Background.defaultProps = {
  className: null,
};

Background.propTypes = {
  className: PropTypes.string,
};

export default Background;
