/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import Modal from 'components/Modal';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 0.5rem 1rem;
  color: #0276f1;
  font-weight: 500;
`;

const StyledModal = styled(Modal)`
  .modal-content {
    border: 3px solid #0276f1;
  }
`;

const Header = styled.div`
  display: flex;
  font-weight: 700;
  justify-content: right;
`;

const Xclose = styled.div`
  margin-left: 20px;
`;

function Warning() {
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <StyledModal open={open} onClose={handleClose}>
      <Wrapper>
        <Header>
          <div onClick={handleClose}>TUTUP</div>
          <Xclose onClick={handleClose}>X</Xclose>
        </Header>
        <div>
          <h3>
            WASPADA PENIPUAN DENGAN MENGATASNAMAKAN TRUSTING SOCIAL INDONESIA
          </h3>
          PT Trusting Social Indonesia mengimbau masyarakat dan institusi agar
          berhati-hati dan waspada terhadap serangkaian skema penipuan yang
          mengatasnamakan perusahaan melalui berbagai saluran komunikasi,
          seperti telepon, surat, SMS, email, WhatsApp, Telegram, dan lainnya.
          Beberapa skema penipuan meliputi penawaran kerjasama dengan PT
          Trusting Social Indonesia yang disertai dengan proposal keuntungan
          dan/atau permintaan transfer dana, mengklaim penerima telah
          memenangkan hadiah, menjanjikan bonus, dividen, atau komisi atas
          penyelesaian tugas, meminta dana untuk berbagai alasan, meminta
          sponsor, atau mengundang ke seminar / lokakarya / acara dengan biaya
          partisipasi. Sebagai tanggapan terhadap kegiatan penipuan ini, kami
          ingin mengklarifikasi beberapa poin berikut:
          <ol>
            <li>
              Klaim tersebut adalah tidak benar dan merupakan tindakan penipuan
              serta penyalahgunaan nama PT Trusting Social Indonesia oleh pihak
              yang tidak bertanggung jawab untuk keuntungan pribadi.
            </li>
            <li>
              PT Trusting Social Indonesia tidak pernah menyelenggarakan atau
              bekerja sama dengan pihak mana pun dalam kegiatan tersebut. Oleh
              karenanya, perusahaan tidak bertanggung jawab atas kerugian yang
              dialami oleh pihak-pihak yang terkena dampak penipuan tersebut.
            </li>
          </ol>
          Untuk mencegah risiko potensial, siapa pun yang menerima permintaan
          seperti itu dapat mengonfirmasi keabsahannya dengan menghubungi email
          resmi: contact@trustingsocial.com. Pengumuman resmi mengenai PT
          Trusting Social Indonesia hanya dapat ditemukan di situs web resmi
          perusahaan (www.trustingsocial.co.id)
        </div>
      </Wrapper>
    </StyledModal>
  );
}

export default Warning;
