import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { Container } from 'react-grid-system';
import { white } from 'themes';
import breakpoints from 'utils/breakpoints';
import Heading from 'components/Heading';
import Paragraph from 'components/Paragraph';
import Item from './Item';

const Wrapper = styled.section`
  padding-top: 90px;
  @media screen and (min-width: ${breakpoints.md}) {
    padding: 2px 0 0;
    background: linear-gradient(
      to bottom,
      #f4f9ff 0%,
      #f4f9ff 50%,
      #ffffff 50%,
      #ffffff 100%
    );
  }
`;
const Description = styled(Paragraph)`
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
`;

const ProductLinks = styled.div`
  margin-top: 70px;

  @media screen and (min-width: ${breakpoints.md}) {
    background: linear-gradient(
      to bottom,
      #f4f9ff 0%,
      #f4f9ff 50%,
      #ffffff 50%,
      #ffffff 100%
    );
    width: 100%;
  }
`;

const ProductsLinkWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 22px;
`;

function OurProducts({ className, title, description, links }) {
  return (
    <Wrapper className={className} id="productSection">
      <Container>
        <Heading centered title={title} />
        <Description>{description}</Description>
        <ThemeProvider theme={white} />
      </Container>
      <ProductLinks>
        <Container>
          <ProductsLinkWrap>
            {links.map(item => (
              <Item key={item.link} {...item} />
            ))}
          </ProductsLinkWrap>
        </Container>
      </ProductLinks>
    </Wrapper>
  );
}

OurProducts.defaultProps = {
  className: null,
};

OurProducts.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
};

export default OurProducts;
