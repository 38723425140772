import React, { useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
import styled from 'styled-components';
import { useScreenClass } from 'react-grid-system';
import * as THREE from 'three';

const Wrapper = styled.div`
  margin: 0px auto;
  padding: 0;
  width: 100%;
  z-index: 2;
  position: absolute;
  max-width: 1319px;
  width: 100%;
  bottom: -25%;
  height: 764px;
  transform: translateX(-50%);
  left: 50%;
  canvas {
    display: block;
  }
`;

function EarthCanvas() {
  const screenClass = useScreenClass();
  const containerRef = useRef();
  useEffect(() => {
    let renderer;
    let scene;
    let camera;
    let skelet;

    const onWindowResize = debounce(() => {
      camera.aspect =
        containerRef.current.clientWidth / containerRef.current.clientHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(
        containerRef.current.clientWidth,
        containerRef.current.clientHeight
      );
    }, 200);

    function animate() {
      requestAnimationFrame(animate);
      skelet.rotation.z -= 0.001;
      skelet.rotation.y = 0;
      skelet.rotation.x = 0;
      renderer.clear();

      renderer.render(scene, camera);
    }

    function init() {
      renderer = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true,
      });
      renderer.setPixelRatio(
        window.devicePixelRatio ? window.devicePixelRatio : 1
      );
      renderer.setSize(
        containerRef.current.clientWidth,
        containerRef.current.clientHeight
      );
      renderer.autoClear = false;
      renderer.setClearColor(0x000000, 0.0);
      containerRef.current.appendChild(renderer.domElement);

      scene = new THREE.Scene();
      scene.fog = new THREE.Fog(0x0276f1, 1, 1000);

      camera = new THREE.PerspectiveCamera(
        25,
        containerRef.current.clientWidth / containerRef.current.clientHeight,
        1,
        1000
      );
      const isMobile = ['xs', 'sm'].includes(screenClass);
      camera.position.z = isMobile ? 1000 : 600;
      camera.position.x = 0;
      camera.position.y = isMobile ? 170 : 100;
      scene.add(camera);

      skelet = new THREE.Object3D();
      scene.add(skelet);

      skelet.position.y = -180;

      const geom = new THREE.IcosahedronGeometry(15, 2);

      const bones = new THREE.MeshPhongMaterial({
        color: 0x0276f1,
        wireframe: true,
        side: THREE.DoubleSide,
      });

      const mesh = new THREE.Mesh(geom, bones);
      mesh.scale.x = 20;
      mesh.scale.y = 20;
      mesh.scale.z = 20;
      skelet.add(mesh);

      const ambientLight = new THREE.AmbientLight(0x0276f1);
      scene.add(ambientLight);

      const directionalLight = new THREE.DirectionalLight(0xffffff);
      directionalLight.position.set(1, 1, 1).normalize();
      scene.add(directionalLight);

      window.addEventListener('resize', onWindowResize, false);
    }

    init();
    animate();

    return () => {
      window.removeEventListener('resize', onWindowResize);
      if (containerRef.current) {
        containerRef.current.removeChild(renderer.domElement);
      }
    };
  }, [screenClass]);

  return <Wrapper ref={containerRef} id="canvas" />;
}

export default EarthCanvas;
