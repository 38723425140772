import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import breakpoints from 'utils/breakpoints';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  padding: 3rem 1.875rem;
  border-radius: 0.63rem;
  background: #f9f9f9;
  text-align: ${props => (props.centered ? 'center' : 'left')};
`;

const Icon = styled.i`
  display: inline-block;
  margin-bottom: 1.25rem;

  @media screen and (min-width: ${breakpoints.md}) {
    margin: 0 0 2rem;
  }
`;

const Title = styled.h5`
  margin: 0 0 1rem 0;
  font-family: Helvetica;
  font-size: 1.125rem;
  font-weight: 700;
  color: #1c2f45;
  letter-spacing: 0;
`;

const Body = styled.div`
  flex: 1;
  font-size: 0.875rem;
  color: #1c2f45;
  letter-spacing: 0;
  line-height: 1.375;
`;

function Card({
  icon,
  title,
  footer,
  children,
  className,
  centered,
  ...props
}) {
  return (
    <Wrapper className={className} centered={centered} {...props}>
      {icon && <Icon>{icon}</Icon>}
      {title && <Title>{title}</Title>}
      <Body>{children}</Body>
      {footer}
    </Wrapper>
  );
}

Card.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  centered: PropTypes.bool,
};

Card.defaultProps = {
  icon: null,
  title: null,
  footer: null,
  children: null,
  centered: false,
  className: null,
};

export default Card;
