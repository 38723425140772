import React from 'react';
import styled from 'styled-components';
import breakpoints from 'utils/breakpoints';
import Card from './index';

const CardWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;

  @media (min-width: ${breakpoints.md}) {
    padding: 1rem 0;
    margin-bottom: 0;
  }
`;

const StyledCard = styled(Card)`
  align-items: center;
  background: none;
  text-align: center;

  p {
    margin: 0;
    font-size: 1rem;
    line-height: 1.75rem;
  }
`;

export default function(props) {
  return (
    <CardWrapper>
      <StyledCard {...props} />
    </CardWrapper>
  );
}
