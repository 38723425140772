import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row, Col, Container } from 'react-grid-system';
import Img from 'components/Img';
import breakpoints from 'utils/breakpoints';
import Heading from 'components/Heading';
import Paragraph from 'components/Paragraph';

const Wrapper = styled.section`
  padding: 40px 0 100px;
  background: #f4f9ff;

  h3 {
    text-align: center;
  }

  ${Paragraph} {
    text-align: center;
    @media screen and (min-width: ${breakpoints.md}) {
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: ${breakpoints.md}) {
    padding-bottom: 5rem;
  }
`;

const LogosWrapper = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0;
  justify-items: stretch;
  justify-content: center;
  align-items: stretch;
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-top: 0.5px solid #dadada;
  border-left: 0.5px solid #dadada;

  @media screen and (min-width: ${breakpoints.md}) {
    margin: 4.25rem 0 0;
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (min-width: ${breakpoints.lg}) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

const ActiveWrapper = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center;
  z-index: 3;
  transition: all 200ms ease;

  span {
    max-width: 80%;
  }
`;

const ActiveImg = styled(Img)`
  height: auto;
  width: 100%;
  display: block;
  transition: all 200ms ease;
`;

const LogoWrapper = styled.li`
  box-sizing: content-box;
  width: 100%;
  border-right: 0.5px solid #dadada;
  border-bottom: 0.5px solid #dadada;
  height: 0;
  padding-bottom: 100%;
  position: relative;

  &:hover ${ActiveWrapper} {
    background: #ffffff;
    box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.3);
    width: 120%;
    height: 120%;
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
function Clients({ className, logos, heading }) {
  return (
    <Wrapper className={className}>
      <Container>
        <Row justify="center">
          <Col md={9}>
            <Heading title={heading} />
          </Col>
        </Row>
        <LogosWrapper>
          {logos.map(item => (
            <LogoWrapper key={item.image}>
              <ActiveWrapper>
                <ActiveImg publicURL={item.image} alt={item.alt} />
              </ActiveWrapper>
            </LogoWrapper>
          ))}
        </LogosWrapper>
      </Container>
    </Wrapper>
  );
}

Clients.defaultProps = {
  className: null,
};

Clients.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string.isRequired,
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      alt: PropTypes.string,
    })
  ).isRequired,
};

export default Clients;
