import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'components/Img';
import { Link } from 'gatsby';
import Paragraph from 'components/Paragraph';
import breakpoints from 'utils/breakpoints';

const Wrapper = styled(Link)`
  text-decoration: none;
  background: #0276f1;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 50px 15px;
  margin: 0 auto;
  transition: all 200ms linear;
  color: #ffffff;

  &:hover {
    box-shadow: 0px 50px 80px -20px rgba(2, 117, 241, 0.4);
  }

  @media screen and (min-width: ${breakpoints.lg}) {
    min-height: 460px;
    flex: 1;
    margin-top: 0;
    padding: 60px 25px;
    flex: none;
  }
`;

const Name = styled.h4`
  font-family: Helvetica;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  margin: 40px 0 20px;
  text-align: center;
`;

const StyledParagraph = styled(Paragraph)`
  text-decoration: none;
  text-align: center;
  margin: 0;
  color: #fff;
`;

function ProductItemLink({ className, image, name, description, link }) {
  return (
    <Wrapper to={link} title={name} className={className}>
      <Img publicURL={image} alt={name} />
      <Name>{name}</Name>
      <StyledParagraph>{description}</StyledParagraph>
    </Wrapper>
  );
}

ProductItemLink.defaultProps = {
  className: null,
};

ProductItemLink.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default ProductItemLink;
